import React, { useEffect, useState } from "react";
import { getData, getDataTemp, postData } from "../../utils/api";
import CloseIcon from "@mui/icons-material/Close";
import PrimaryModal from "../../common/Modal/PrimaryModal";
import PrimaryButton from "../../common/FormElements/Button/PrimaryButton";
import { useAuthentication } from "../../context/authContext";
import { enqueueSnackbar } from "notistack";
import { formatErrorMessage } from "../../utils/formatErrorMessage";
import Text from "../../common/Text";
import PaymentHistoryTable from "../../component/paymentHistoryScreen/PaymentHistoryTable";
import moment from "moment";
import paymentIcons from "../../assets/img/paymentIcons.png";
import Image from '../../component/image'
import SecondaryButton from "../../common/FormElements/Button/SecondaryButton";
import './subold.css'

const Subold = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [opencancelModal, setopencancelModal] = useState(false);
  const [openpaymentplanModal, setopenpaymentModal] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState();
  const [locationcount, setLocationCount] = useState("");
  const [selectedBusinessID, setSelectedBusinessID] = useState();
  const [currentsubscriptiondata, setcurrentsubscriptiondata] = useState();
  const [transaction, settransactiondata] = useState();
  //console.log(currentsubscriptiondata,'current Sub data');
  const [errors, seterrors] = useState("");

  const {
    businessUser: { businessProfile = '' } = {},
  } = useAuthentication();

  const fetchAllBusinessProfiles = async () => {
    setLoading(true);
    setData(businessProfile);
    setLoading(false);
  };
  const fetchtransactions = async () => {
    setLoading(true);
    try {
      const res = await getData("business-profile/transactions/all");
      if (res.data) {
        settransactiondata(res.data.transactions);
      } else {
        console.log(res, "Error while fetching tranasactions");
      }
    } catch (error) {
      console.error("Error while fetching tranasactions:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchAllSubscriptionProduct = async () => {
    setLoading(true);
    try {
      const res = await getDataTemp("user/subscription-products");
      if (res.data) {
        setSubscriptionData(res.data.data);
      } else {
        console.log(res, "Error while fetching subscription products");
      }
    } catch (error) {
      console.error("Error while fetching subscription products:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchcurrentsubscription = async () => {
    setLoading(true);
    try {
      const res = await getData("subscription");
      if (res.data) {
        setcurrentsubscriptiondata(res.data.data[0]);
      } else {
        console.log(res, "Error while fetching subscription products");
      }
    } catch (error) {
      console.error("Error while fetching subscription products:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleCancelSubscription = async () => {
    setLoading(true);
    try {
      const res = await postData(
        `user/cancel-subscription/${currentsubscriptiondata?._id}`
      );
      if (res.data) {
        enqueueSnackbar(
          res.data.message ?? "Subscription canceled successfully",
          {
            variant: "success",
          }
        );
        handleCloseBusinessModal();
      } else {
        enqueueSnackbar(
          res.error?.message
            ? formatErrorMessage(res.error?.message)
            : "Something went wrong",
          {
            variant: "error",
          }
        );
      }
    } catch (error) {
      enqueueSnackbar("Error canceling subscription: " + error.message, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllSubscriptionProduct();
    fetchAllBusinessProfiles();
    fetchcurrentsubscription();
    fetchtransactions();
  }, []);

  const handleOpenBusinessModal = (id) => {
    setSelectedBusinessID(id);
    setOpenModal(true);
  };

  const opencancelfunModal = () => {
    setopencancelModal(true);
  };

  const openpaymentModal = () => {
    setopenpaymentModal(true);
  };

  const handleCloseBusinessModal = () => {
    setSelectedBusinessID(null);
    setOpenModal(false);
    setopencancelModal(false);
    setopenpaymentModal(false);
  };

  const validateFields = () => {
    let isValid = true;
    const errorsCopy = { errors };

    if (!locationcount) {
      errorsCopy = "Location is required";
      isValid = false;
    } else if (locationcount < 1) {
      errorsCopy = "Location must be greater than or equals to 1";
      isValid = false;
    } else {
      errorsCopy = "";
    }
    seterrors(errorsCopy);
    return isValid;
  };
  //console.log(subscriptionData[0],'dede')
  const onSubmit = async (e) => {
    setLoading(true);
    //const isValid = validateFields();
    const isValid = true;
    if (isValid) {
      const getCard = await getData("user/payment-methods");
      if (getCard.data) {
        let subInfo = businessProfile?.subscriptionType;
        const matchedSubInfo = subscriptionData.filter((sub) =>
          subInfo.includes(sub.name)
        );
        //let proprationdate = new Date(currentsubscriptiondata.endDate);

        const interval =
          currentsubscriptiondata?.product?.durationType == "monthly"
            ? "month"
            : "year";

        const priceId = subscriptionData.find(
          (item) => item.prices[0].recurring.interval === interval
        ).prices[0].id;

        const stripeData = {
          paymentMethodId: getCard.data.data.data[0].id,
          businessProfileId: selectedBusinessID,
          priceId: priceId,
          quantity: Number(locationcount),
          saveCard: false,
        };
        const createSubscription = await postData(
          "business-profile/add/prorate",
          {
            ...stripeData,
          }
        );
        if (createSubscription.data) {
          enqueueSnackbar(createSubscription.data.message ?? "", {
            variant: "success",
          });
          window.location.reload();
          handleCloseBusinessModal();
        } else {
          enqueueSnackbar(
            createSubscription.error?.message
              ? formatErrorMessage(createSubscription.error?.message)
              : "Something went wrong",
            {
              variant: "error",
            }
          );
        }
      }
    }
    setLoading(false);
  };

  const handleCloseLocationModal = () => {
    handleCloseBusinessModal();
    setLocationCount("");
  };

  return (
    <>
      <div className="md:mx-10 mx-5">
        <div className="flex md:items-center md:gap-5 gap-2 md:mb-0 mb-3">
          <div className="flex flex-col md:w-1/2 w-full">
            <Text className="text-[24px] mob:text-[16px] font-bold whitespace-nowrap">
              Subscription and Billing
            </Text>
            <Text className="text-base font-bold text-black mb-5">
              Subscription Details
            </Text>
          </div>
          <div className="flex justify-center md:justify-start md:w-1/2 w-full md:ml-8">
            <Image 
              src={paymentIcons} 
              alt="payment icons" 
              className="w-[150px] h-[30px] md:w-[230px] md:h-[40px]" 
            />
          </div>
        </div>

        <div>
          {currentsubscriptiondata?.isCancelled && (data && data?.subscriptions && data?.subscriptions?.[0]?.isCancelled) ? (
            <h2 className="text-[20px] text-red-500 font-bold mb-1.5">
              Subscription is cancelled
            </h2>
          ) : (
            ""
          )}
        </div>

        {loading ? (
          "loading ... "
        ) : (
          <div className="mb-6">
            <div className="form-part">
              <div className="flex items-center md:gap-5 gap-2 md:flex-row flex-col md:mb-0 mb-3">
                <div className="form-side-part md:w-1/2 w-full grid md:grid-cols-2 grid-cols-1 gap-2.5">
                  <div className="mt-3 relative">
                    <label className="common-lable" htmlFor="amount">
                      Cost/ Location
                    </label>
                    {/* <span className="currency-symbol">$</span> */}
                      <input
                        placeholder="Amount"
                        className="common-input common-input-right !border"
                        type="text"
                        name="Pamount"
                        value={
                          `$ ${currentsubscriptiondata?.product?.price.toFixed(2) ?? 0}`
                        }
                        readOnly
                      />
                  </div>
                  <div className="mt-3 relative ">
                    <label className="common-lable" htmlFor="Location">
                      Locations
                    </label>
                    <input
                      placeholder="Location"
                      className="common-input common-input-right !border"
                      type="number"
                      min="1"
                      name="locationcount"
                      value={data?.locationCount || ""}
                      readOnly
                    />
                  </div>

                  {/* <div className="mt-3 relative">
                    <label className="common-lable" htmlFor="paymentplan">
                      Payment Plan
                    </label>
                    <input
                      placeholder="Payment Plan"
                      className="common-input !border"
                      type="text"
                      name="paymentplan"
                      value={data?.subscriptionType || ""}
                      readOnly
                    />
                  </div> */}
                  {/* <div className="mt-2 relative">
                      <label className="common-lable" htmlFor="amount">Per Location</label>
                      <input
                        placeholder="Amount" className="common-input !border"
                        type="number" name="amount" value={ (currentsubscriptiondata?.product?.price).toFixed(2) ?? 0} readOnly
                      />
                    </div> */}
                  {/* <div className="mt-3 relative ">
                    <label className="common-lable" htmlFor="amount">
                      Total Amount
                    </label>
                    <span className="currency-symbol">$</span>
                    <input
                      placeholder="Amount"
                      className="common-input !border"
                      type="number"
                      name="amount"
                      value={
                        (
                          currentsubscriptiondata?.product?.price *
                          data?.locationCount
                        ).toFixed(2) ?? 0
                      }
                      readOnly
                    />
                  </div> */}
                </div>
                {currentsubscriptiondata?.isCancelled ? (
                  ""
                ) : (
                  <div className="md:justify-start flex justify-end form-side-btn-part md:w-1/2 w-full">
                    <PrimaryButton
                      onClick={() => handleOpenBusinessModal(data?._id)}
                      inputClass={"max-w-[250px]"}
                    >
                      <span>+/- Locations</span>
                    </PrimaryButton>
                  </div>
                )}
              </div>

              <div className="flex items-center md:gap-5 gap-2 md:flex-row flex-col md:mb-0 mb-3">
                <div className="form-side-part md:w-1/2 w-full grid md:grid-cols-2 grid-cols-1 gap-2.5">

                  <div className="mt-3 relative ">
                    <label className="common-lable" htmlFor="amount">
                      Total Amount
                    </label>
                    {/* <span className="currency-symbol">$</span> */}
                    <input
                      placeholder="Amount"
                      className="common-input common-input-right !border"
                      type="text"
                      name="amount"
                      value={
                        `$ ${(
                          currentsubscriptiondata?.product?.price *
                          data?.locationCount
                        ).toFixed(2) ?? 0}`
                      }
                      readOnly
                    />
                  </div>

                  <div className="mt-3 relative">
                    <label className="common-lable" htmlFor="paymentplan">
                      Plan
                    </label>
                    <input
                      placeholder="Payment Plan"
                      className="common-input common-input-right !border"
                      type="text"
                      name="paymentplan"
                      value={data?.subscriptionType || ""}
                      readOnly
                    />
                  </div>

                  {/* <div className="mt-3 relative">
                    <label className="common-lable" htmlFor="paymentmethod">
                      Payment Method
                    </label>
                    <input
                      placeholder="Payment Method"
                      className="common-input !border"
                      type="text"
                      name="paymentmethod"
                      value={"Card"}
                      readOnly
                    />
                  </div> */}

                  {/* <div className="mt-3 relative">
                    <label className="common-lable" htmlFor="status">
                      Status
                    </label>
                    <input
                      placeholder="Status"
                      className="common-input !border"
                      type="text"
                      name="status"
                      value={"Active"}
                      readOnly
                    />
                  </div> */}
                </div>

                  <div className="md:justify-start flex justify-end form-side-btn-part md:w-1/2 w-full">
                 {/*   <PrimaryButton onClick={() => openpaymentModal()}
                      inputClass={"max-w-[250px]"}>
                      <span>Change Plan</span>
                    </PrimaryButton>
                  */}
                  </div>
                 
              </div>

              <div className="flex items-center md:gap-5 gap-2 md:flex-row flex-col md:mb-0 mb-3">
                <div className="form-side-part md:w-1/2 w-full grid md:grid-cols-2 grid-cols-1 gap-2.5">
                  {/* <div className="mt-3 relative">
                    <label
                      className="common-lable"
                      htmlFor="currentbillingperiodstart"
                    >
                      Current Billing Period Start
                    </label>
                    <input
                      placeholder="Current Billing Period Start"
                      className="common-input !border"
                      type="text"
                      name="currentbillingperiodStart"
                      value={moment(currentsubscriptiondata?.startDate).format(
                        "DD-MMM-YY"
                      )}
                      readOnly
                    />
                  </div>

                  <div className="mt-3 relative">
                    <label
                      className="common-lable"
                      htmlFor="currentbillingperiodend"
                    >
                      Current Billing Period End
                    </label>
                    <input
                      placeholder="Current Billing Period end"
                      className="common-input !border"
                      type="text"
                      name="currentbillingperiodend"
                      value={moment(currentsubscriptiondata?.endDate).format(
                        "DD-MMM-YY"
                      )}
                      readOnly
                    />
                  </div> */}

                  <div className="mt-3 relative">
                    <label className="common-lable" htmlFor="status">
                      Status
                    </label>
                    <input
                      placeholder="Status"
                      className="common-input common-input-right !border"
                      type="text"
                      name="status"
                      value={data && data?.subscriptions && data?.subscriptions?.[0]?.isCancelled ? "Cancelled" : "Active"}
                      readOnly
                    />
                  </div>

                  <div className="mt-3 relative">
                    <label className="common-lable" htmlFor="paymentmethod">
                      Payment Method
                    </label>
                    <input
                      placeholder="Payment Method"
                      className="common-input common-input-right !border"
                      type="text"
                      name="paymentmethod"
                      value={"Card"}
                      readOnly
                    />
                  </div>

                </div>

                 <div className="md:justify-start flex justify-end form-side-btn-part md:w-1/2 w-full">
                   {/* <PrimaryButton onClick={() => openpaymentModal()}
                      inputClass={"max-w-[250px]"}>
                      <span>Change Card</span>
                    </PrimaryButton> */}
                  </div>
                 
              </div>

              {currentsubscriptiondata?.isCancelled ? (
                ""
              ) : (
                <div className="flex items-center md:gap-5 gap-2 md:flex-row flex-col md:mb-0 mb-3">
                  <div className="form-side-part md:w-1/2 w-full grid md:grid-cols-2 grid-cols-1 gap-2.5">

                    <div className="mt-3 relative">
                      <label
                        className="common-lable"
                        htmlFor="currentbillingperiodstart"
                      >
                        Current Period
                      </label>
                      <input
                        placeholder="Current Billing Period Start"
                        className="common-input common-input-right !border"
                        type="text"
                        name="currentbillingperiodStart"
                        value={moment(currentsubscriptiondata?.startDate).format(
                          "DD-MMM-YY"
                        )}
                        readOnly
                      />
                    </div>

                    <div className="mt-3 relative">
                      <label
                        className="common-lable"
                        htmlFor="nextbillingperiodstart"
                      >
                        Next Period
                      </label>
                      <input
                        placeholder="Next Billing Period Start"
                        className="common-input common-input-right !border"
                        type="text"
                        name="nextbillingperiodStart"
                        value={moment(currentsubscriptiondata?.endDate).format(
                          "DD-MMM-YY"
                        )}
                        readOnly
                      />
                    </div>

                    {/* <div className="mt-3 relative">
                      <label
                        className="common-lable"
                        htmlFor="nextbillingperiodend"
                      >
                        Next Billing Period End
                      </label>
                      <input
                        placeholder="Next Billing Period end"
                        className="common-input !border"
                        type="text"
                        name="nextbillingperiodend"
                        value={
                          data?.subscriptionType == "Monthly"
                            ? moment(currentsubscriptiondata?.endDate)
                                .add(1, "M")
                                .format("DD-MMM-YY")
                            : moment(currentsubscriptiondata?.endDate)
                                .add(1, "y")
                                .format("DD-MMM-YY")
                        }
                        readOnly
                      />
                    </div> */}
                  </div>

                  <div className="md:justify-start flex justify-end form-side-btn-part md:w-1/2 w-full">
                    <PrimaryButton
                      onClick={() => opencancelfunModal()}
                      inputClass={"max-w-[250px]"}
                    >
                      <span>Cancel</span>
                    </PrimaryButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="text-base font-bold text-black mb-5">
          Billing History
          <PaymentHistoryTable data={transaction} />
        </div>
      </div>

      <PrimaryModal
        open={openModal || !!selectedBusinessID}
        handleClose={handleCloseLocationModal}
      >
        <div className="flex flex-col h-full">
          <div className="pt-2 pb-4 flex justify-between">
            <h1 className="text-base font-semibold">
              Change Number of locations
            </h1>
            <CloseIcon
              onClick={handleCloseLocationModal}
              className="w-6 h-6 text-[#9A9A9A] cursor-pointer"
            />
          </div>
          <h1 className="text-base font-semibold">Number of Locations</h1>
          <div className="text-gray-500 font-medium text-sm mb-1.5">
            This is the maximum number of locations (your stores, venues, outlet
            etc.) that you can publish Offers and Events at per day.
          </div>

          <div className="flex gap-4 items-center mb-4">
            <div className="mt-3 relative">
              <label className="common-lable" htmlFor="Location">
                Current
              </label>
              <input
                placeholder="Location"
                className="common-input common-input-right max-w-24 !border"
                value={data?.locationCount}
                type="number"
                min="1"
                name="clocationcount"
                readOnly
              />
            </div>
            <div className="mt-3 relative">
              <label className="common-lable" htmlFor="Required">
                Required
              </label>
              <input
                // placeholder="Required"
                className="common-input common-input-right max-w-24 !border"
                value={locationcount}
                type="number"
                min="1"
                name="locationcount"
                required
                onChange={(e) => setLocationCount(e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <h1 className="text-lg font-bold text-[#45818E]">
                $
                {locationcount
                  ? (
                      currentsubscriptiondata?.product?.price * locationcount
                    ).toFixed(2) ?? 0
                  : (
                      currentsubscriptiondata?.product?.price *
                      data?.locationCount
                    ).toFixed(2)}
                <span className="font-semibold text-base capitalize">
                  /{currentsubscriptiondata?.product?.durationType}
                </span>
              </h1>
              <h2 className="text-gray-500 font-medium text-sm mb-1.5">
                Recurring {currentsubscriptiondata?.product?.durationType} until
                cancelled
              </h2>
            </div>
            {errors && (
              <span className="font-semibold pl-1 text-sm text-red-600">
                {errors}
              </span>
            )}
          </div>
          <div className=" relative">
            <div>
              <div>
                {Number(locationcount) != NaN &&
                Number(locationcount) != data?.locationCount ? (
                  data?.locationCount < locationcount ? (
                    <>
                      <div className="mb-4">
                        <h1 className="text-base font-semibold">
                          Billing Adjustment
                        </h1>
                        <p className="text-gray-500 font-medium text-sm mb-1.5">
                          You've increased from {data?.locationCount} to{" "}
                          {locationcount} locations, so we need to charge for
                          these additional locations for the remaining{" "}
                          {moment(currentsubscriptiondata.endDate).diff(
                            moment(),
                            "days"
                          )}{" "}
                          days in the current billing period, ending{" "}
                          {moment(currentsubscriptiondata?.endDate).format(
                            "Do MMMM YYYY"
                          )}
                          .
                        </p>
                        <p className="text-gray-500 font-medium text-sm mb-1.5">
                          Your card will be charged $
                          {currentsubscriptiondata.isTrialActive
                            ? 0
                            : data?.subscriptionType === "Monthly"
                            ? (
                                Math.abs(
                                  (currentsubscriptiondata?.product?.price /
                                    30) *
                                    moment().diff(
                                      moment(currentsubscriptiondata.endDate),
                                      "days"
                                    )
                                ) *
                                (locationcount - data?.locationCount)
                              ).toFixed(2)
                            : (
                                Math.abs(
                                  (currentsubscriptiondata?.product?.price /
                                    365) *
                                    moment().diff(
                                      moment(currentsubscriptiondata.endDate),
                                      "days"
                                    )
                                ) *
                                (locationcount - data?.locationCount)
                              ).toFixed(2)}{" "}
                          today for these extra locations.
                        </p>
                      </div>

                      <div className="mb-4">
                        <h1 className="text-base font-semibold">
                          {" "}
                          New Subscription Details
                        </h1>
                        <p className="text-gray-500 font-medium text-sm mb-1.5">
                          Your card will be charged $
                          {(
                            currentsubscriptiondata?.product?.price *
                            locationcount
                          ).toFixed(2)}{" "}
                          {data?.subscriptionType} from{" "}
                          {moment(
                            currentsubscriptiondata?.invoiceStartDate
                          ).format("Do MMMM YYYY")}
                          . You can cancel at any time.
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      {locationcount !== "" && (
                        <div className="billingperiod mb-4">
                          <h2 className="text-base font-semibold">
                            Current Billing Period
                          </h2>
                          <p className="text-gray-500 font-medium text-sm mb-1.5">
                            You've decreased from {data?.locationCount} to{" "}
                            {locationcount} locations.
                          </p>
                        </div>
                      )}
                      {Boolean(Number(locationcount || 0)) && (
                        <div className="newsubdetails mb-4">
                          <h2 className="text-base font-semibold">
                            New Subscription Details
                          </h2>
                          <p className="text-gray-500 font-medium text-sm mb-1.5">
                            Your card will be charged $
                            {currentsubscriptiondata?.product?.price.toFixed(
                              2
                            ) * locationcount ?? 0}{" "}
                            annual from{" "}
                            {moment(
                              currentsubscriptiondata?.invoiceStartDate
                            ).format("Do MMMM YYYY")}
                            . You can cancel at any time.
                          </p>
                        </div>
                      )}
                    </>
                  )
                ) : null}
              </div>
            </div>
          </div>

          <div className="flex gap-1.5 justify-end mb-3">
            <SecondaryButton
              inputClass="bg-[#E3E3D8] tex-[#74746E] max-w-[140px]"
              onClick={handleCloseLocationModal}
            >
              <>Back</>
            </SecondaryButton>
            <PrimaryButton
              onClick={onSubmit}
              loading={loading}
              disabled={loading}
              inputClass={"max-w-[144px]"}
              isDisabled={
                locationcount == "" ||
                Number(locationcount) == NaN ||
                Number(locationcount) == data?.locationCount
              }
            >
              <span>Update</span>
            </PrimaryButton>
          </div>
        </div>
      </PrimaryModal>

      {/*Cancel subscription */}
      <PrimaryModal
        open={opencancelModal}
        handleClose={handleCloseBusinessModal}
      >
        <div className="flex flex-col h-full">
          <div className="pt-2 pb-4 flex justify-between">
            <h1 className="text-xl font-semibold">Cancel Subscription</h1>
            <CloseIcon
              onClick={handleCloseBusinessModal}
              className="w-6 h-6 text-[#9A9A9A] cursor-pointer"
            />
          </div>

          <div>
            <h2 className="text-base font-semibold">Subscription details</h2>
            <p>
              Your current subscription ends on{" "}
              {moment(currentsubscriptiondata?.invoiceEndDate).format(
                "DD-MMM-YY"
              )}
              . If you cancel now, you will no longer be able to publish content
              after this date.
            </p>
            <br />
            <p>Are you sure you wish to cancel?</p>
          </div>

          <div className="flex gap-1.5 justify-end mb-3">
            <SecondaryButton
              inputClass="bg-[#E3E3D8] tex-[#74746E] max-w-[140px]"
              onClick={handleCloseBusinessModal}
            >
              <>Back</>
            </SecondaryButton>
            <PrimaryButton
              loading={loading}
              disabled={loading}
              inputClass={"max-w-[144px]"}
              onClick={handleCancelSubscription}
            >
              <span>Proceed</span>
            </PrimaryButton>
          </div>
        </div>
      </PrimaryModal>

      {/*Change Payment Plan subscription */}
      <PrimaryModal
        open={openpaymentplanModal}
        handleClose={handleCloseBusinessModal}
      >
        <div className="flex flex-col h-full">
          <div className="pt-2 pb-4 flex justify-between">
            <h1 className="text-xl font-semibold">Change Payment Plan</h1>
            <CloseIcon
              onClick={() => handleCloseBusinessModal()}
              className="w-6 h-6 text-[#9A9A9A] cursor-pointer"
            />
          </div>
          <div className="mb-3">
            <h1 className="text-base font-bold mb-3">Payment Plan</h1>
            <div className="flex  items-center space-x-2">
              <div className="flex  items-center space-x-2">
                {subscriptionData &&
                  subscriptionData.map((item, index) => (
                    <button
                      key={index}
                      className="bg-tertiaryDark active text-[#7C7C72] rounded-lg w-[109px] h-[48px] flex justify-center items-center cursor-default"
                    >
                      {item.name}
                    </button>
                  ))}
              </div>
              <p className="text-base font-normal text-[#009CA6]">
                $479.99 / Year / Location
              </p>
            </div>
            <div className="w-full border-b border-[#DDDDD7] mt-3" />
          </div>
          <div>
            <h2 className="text-base font-semibold">Subscription details</h2>
            <p className="text-gray-500 font-medium text-sm mb-1.5">
              Your have switched from the Monthly to Annual Payment Plan for
              your 5 locations.
            </p>
            <br></br>
            <p className="text-gray-500 font-medium text-sm mb-1.5">
              The new Payment Plan will start from the next billing period. Your
              card will be charged $2339.5 Yearly starting 6th April 2024. You
              can cancel at any time.
            </p>
          </div>

          <div className="flex gap-1.5 justify-end mb-3">
            <SecondaryButton
              inputClass="bg-[#E3E3D8] tex-[#74746E] max-w-[140px]"
              onClick={() => handleCloseBusinessModal()}
            >
              <>Back</>
            </SecondaryButton>
            <PrimaryButton loading={loading} disabled={loading} inputClass={"max-w-[144px]"}>
              <span>Proceed</span>
            </PrimaryButton>
          </div>
        </div>
      </PrimaryModal>
    </>
  );
};

export default Subold;
